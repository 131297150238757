.items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  justify-content: space-between;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  background: var(--bg-color);
  padding: 0 var(--spacing-80);
}

.carouselContainer {
  display: flex;
  gap: var(--spacing-24);
  grid-column: full-start / full-end;
}

.carouselSlide {
  position: relative;
  display: block;
  width: fit-content;
  flex: 1 0 auto;
  width: 100%;
}

.carouselSlide img, .slideCard, .slideContainer{
  height: 100%;
}

.desktop{
  display: grid;
}

.tablet{
  display: none;
}


@media (max-width: 1200px) {

  .carouselSlide{
    max-width: 450px;
  }

  .tablet{
    display: grid;
    grid-template-columns: 1fr;
  }
  
  .desktop {
    display: none;
  }
}

@media (max-width: 1024px) {
  .carousel{
    padding: var(--spacing-24);
  }
  .carouselSlide{
    max-width: 350px;
    height: auto;
    width: 95%;
  }
}

@media (max-width: 480px) {
  .carousel{
    padding: var(--spacing-16);
  }
  .carouselContainer {
    gap: var(--spacing-16);
  }
}

